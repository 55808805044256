import React, { useEffect, useState, useMemo } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import banIcon from "assets/images/ban-icon.svg";
import { useNavigate } from "react-router-dom";
import {
  getALlPayoutsApproval,
  payoutMethodApprove,
  payoutMethodReject,
  actionSeller,
  getAllPayoutMethodList,
} from "services/SellerPayoutApprovals";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "../../../components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import SellerActionPopup from "components/Common/Popups/SellerActionPopup";
import useToggle from "hooks/useToggle";
import Select from "react-select";
import ResetButton from "components/Common/ResetButton";
import ApprovePaymentPopup from "components/Common/Popups/ApprovePaymentPopup";

function PayoutMethodsApproval() {
  const [payoutsApprovalList, setPayoutsApprovalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [searchVal, setSearch] = useState(null);
  const [actionFields, setActionFields] = useState({});
  const [isRejectBan, setRejectBan] = useToggle(false);
  const [selectedSellerIds, setSellectedSellerIds] = useState([]);
  const [payoutStatus, setPayoutStatus] = useState([]);
  const [options, setOptions] = useState([]);
  const [usdcRecipientId, setUsdcRecipientId] = useState();
  const [uniqueInfo, setUniqueInfo] = useState("");
  const [isApprovedPayout, setIsApprovedPayout] = useState(false);

  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const hasSellerRejectPer = user?.permissions?.some(
    p => p.name === "hyperwallet-reject-seller-ban"
  );
  const params = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(params.keys()).length;

  const getAllPayout = async () => {
    try {
      const res = await getAllPayoutMethodList();
      const customTypes = res?.data?.payouts?.map(item => ({
        label:
          item.name == "Hyperwallet Paypal"
            ? "Paypal"
            : item?.name == "Hyperwallet Wire"
            ? "Wire"
            : item.name,
        value: item.id,
      }));
      setOptions(customTypes);
      return customTypes;
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = useMemo(() => {
    return getAllPayout();
  }, []);

  useEffect(() => {
    setPageTitle("Payout Method Approval - Inspire Uplift");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fetchPayoutList = await getOptions;
      const status = params.get("status");
      const search = params?.get("str") || null;
      const page = params?.get("page") || undefined;
      const payoutTypeParam = params?.get("payout_type") || undefined;
      let payoutStatus;
      if (payoutTypeParam) {
        let findPayoutType = fetchPayoutList?.find(
          item => item.label == payoutTypeParam
        );
        payoutStatus = findPayoutType?.value;

        setPayoutStatus(findPayoutType);
      } else {
        setPayoutStatus([]);
      }
      setPageNo(page || 1);
      setSearch(search || "");
      let filterStatus;
      if (status == "Approved") {
        filterStatus = 1;
      } else if (status == "Rejected") {
        filterStatus = 2;
      } else if (status == "pending") {
        filterStatus = -1;
      } else if (status == "disabled") {
        filterStatus = 5;
      } else {
        filterStatus = undefined;
      }
      setStatus(filterStatus);

      getPayout(page, search, filterStatus, payoutStatus);
    };
    fetchData();
  }, [window?.location?.search]);

  const history = useNavigate();

  const getPayout = (page, search, status, type) => {
    setLoading(true);
    getALlPayoutsApproval(page, search, status, type)
      .then(res => {
        setPayoutsApprovalList(res.data);
        setLastPage(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "shop_name",
      text: "Store Name",
      formatter: (cell, row) => <div>{row?.seller?.shop_name}</div>,
    },
    {
      dataField: "email",
      text: "Seller Email",
      formatter: (cell, row) => <div>{row?.seller?.email}</div>,
    },
    {
      dataField: "seller status",
      text: "Seller Status",
      formatter: (cellContent, row) => {
        let status;
        if (row?.seller?.is_verify === 0 && row?.seller?.is_active === 1) {
          status = "Not Verified";
        }
        if (
          (row?.seller?.is_verify === 0 || row?.seller?.is_verify === 1) &&
          row?.seller?.is_active === 0
        ) {
          status = "Temporary Ban";
        }
        if (
          (row?.seller?.is_verify === 0 || row?.seller?.is_verify === 1) &&
          row.seller.is_active === 2
        ) {
          status = "Banned";
        }
        if (row?.seller?.is_verify === 1 && row?.seller?.is_active === 1) {
          status = "Verified";
        }
        if (row?.seller?.is_active === 3) {
          status = "Delete";
        }
        return (
          <span
            className={`${
              status === "Verified"
                ? "success-status"
                : status === "Banned" ||
                  status === "Temporary Ban" ||
                  status === "Delete"
                ? "danger-status"
                : status === "Not Verified"
                ? "warning-status"
                : ""
            } status w-max-content`}
          >
            {status}
          </span>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => <div>{row?.created_at}</div>,
    },
    {
      dataField: "payment type",
      text: "Payout Method Type",
      formatter: (cell, row) => (
        <div className="">
          {row?.payout_method?.name == "Hyperwallet Paypal"
            ? "Paypal"
            : row?.payout_method?.name == "Hyperwallet Wire"
            ? "Wire"
            : row?.payout_method?.name}
        </div>
      ),
    },
    {
      dataField: "business_entity",
      text: "Entity",
      formatter: (cell, row) => <div>{row?.data?.business_entity}</div>,
    },

    {
      dataField: "country",
      text: "Country",
      formatter: (cell, row) => <div>{row?.data?.country || "N/A"}</div>,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row?.status) {
          case 1:
            status = "Approved";
            break;
          case 2:
            status = "Rejected";
            break;
          case 5:
            status = "Disabled";
            break;
          default:
            status = "Pending";
        }
        return (
          <div
            className={`status ${
              status === "Pending"
                ? "warning-status"
                : status === "Approved"
                ? "success-status"
                : status === "Rejected" || status === "Disabled"
                ? "danger-status"
                : ""
            }  w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div
            className={`d-flex align-items-center   ${
              loading && "pe-none opacity-25"
            } ${
              (row.status === 1 ||
                row.status === 2 ||
                !row?.seller ||
                row.status == 5) &&
              "d-none"
            }`}
          >
            {permissions.includes("hyperwallet-reject-seller") && (
              <div
                className="action-btn delete-btn-bg me-1 cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  setSelectedId(row.id);
                  setShowDeleteModal(!showDeleteModal);
                }}
              >
                <img src={cancleIcon} className="" alt="reject icon" />
              </div>
            )}

            {permissions.includes("hyperwallet-approve-seller") && (
              <div
                className="action-btn approve-btn-bg cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  setSelectedId(row.id);
                  setIsApprovedPayout(true);
                }}
              >
                <img src={tickIcon} className="" alt="approve icon" />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const data = payoutsApprovalList?.data?.map(item => {
    return {
      ...item,
    };
  });

  let nonSelected = payoutsApprovalList?.data?.map(item => {
    if (item.status !== 0 || item?.seller == null) {
      return item.id;
    }
  });

  const handleOnSelectAll = (isSelected, rows) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
      setSellectedSellerIds(rows?.map(row => row?.seller?.id));
    } else {
      setSelectedRowKeys([]);
      setSellectedSellerIds([]);
    }
  };

  const handlePopup = () => {
    setRejectBan(!isRejectBan);
    setActionFields({});
  };

  const handleSelectChange = selected => {
    setPayoutStatus(selected);
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectable: nonSelected,
    onSelectAll: handleOnSelectAll,
    nonSelectableClasses: "hide-checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
        setSellectedSellerIds([...selectedSellerIds, row.seller?.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
        setSellectedSellerIds(
          selectedSellerIds.filter(key => key !== row.seller?.id)
        );
      }
    },
    selected: selectedRowKeys,
  };

  const handleConfirm = e => {
    handlePayout(e, "reject", selectedId, "single");
  };

  const handleSellerRejectBan = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldObj = { ...actionFields };
    fieldObj[name] = value;
    setActionFields(fieldObj);
  };

  const updatedPayout = filterData => {
    if (filterData?.length == 0 && pageNo > 1) {
      params.set("page", pageNo - 1);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      history(newUrl, { replace: true });
    } else {
      getALlPayoutsApproval(pageNo, searchVal, status, payoutStatus?.value)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setPayoutsApprovalList(res.data);
          setLastPage(res.data.last_page);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    }
  };

  const handlePayout = (e, actionStatus, id, type, payoutMethod) => {
    e.stopPropagation();
    const filterData = payoutsApprovalList?.data?.filter(payout => {
      if (type == "single") {
        return payout.id !== id;
      } else {
        return !selectedRowKeys?.includes(payout.id);
      }
    });
    const data = {
      payout_method_ids: type && type === "single" ? [id] : selectedRowKeys,
      usdc_recipient_id:
        actionStatus === "approve" ? usdcRecipientId : undefined || undefined,
      unique_info: uniqueInfo
        ? actionStatus === "approve"
          ? uniqueInfo
          : undefined
        : undefined,
    };
    toast.dismiss();
    setLoading(true);
    if (actionStatus === "reject") {
      payoutMethodReject(data)
        .then(res => {
          if (res.status === 200) {
            PrimaryToast({ type: "success", message: res?.data?.message });
            setShowDeleteModal(false);
            setSelectedId("");
            setSelectedRowKeys([]);
            updatedPayout(filterData);
          }
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    } else if (actionStatus === "approve") {
      payoutMethodApprove(data)
        .then(res => {
          if (res.status === 200) {
            PrimaryToast({ type: "success", message: res?.data?.message });
            setShowDeleteModal(false);
            setSelectedId("");
            setSelectedRowKeys([]);
            setUsdcRecipientId();
            handleClosePopup();
            updatedPayout(filterData);
          }
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    }
  };

  const handleSellerAction = () => {
    const data = {
      seller_ids: selectedSellerIds,
      reason: actionFields?.reason,
      ban_status: actionFields?.ban_status ? 2 : 0,
      payout_ids: selectedRowKeys,
    };
    const filterData = payoutsApprovalList?.data?.filter(payout => {
      return !selectedRowKeys?.includes(payout.id);
    });
    setLoading(true);
    actionSeller(data)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        handlePopup();
        setShowDeleteModal(false);
        setSelectedId("");
        setSelectedRowKeys([]);
        setSellectedSellerIds([]);
        updatedPayout(filterData);
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  const handleFilter = () => {
    const page = params?.get("page");
    let filterStatus;
    if (status == 1) {
      filterStatus = "Approved";
    } else if (status == 2) {
      filterStatus = "Rejected";
    } else if (status == -1) {
      filterStatus = "pending";
    } else if (status == 5) {
      filterStatus = "disabled";
    }
    if (Object.keys(payoutStatus)?.length > 0) {
      params?.set("payout_type", payoutStatus?.label);
    } else {
      params?.delete("payout_type");
    }
    if (searchVal) {
      params?.set("str", searchVal);
    } else {
      params?.delete("str");
    }
    if (filterStatus) {
      params.set("status", filterStatus);
    } else {
      params.delete("status");
    }
    if (page) {
      params.delete("page");
    }
    // Update the URL with the new search parameters
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    // setPageNo(1);
    history(newUrl, { replace: true });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "999px",
    }),
  };

  const handleClosePopup = () => {
    setIsApprovedPayout(false);
    setUniqueInfo("");
    setSelectedId("");
  };

  const handlePayoutApprove = e => {
    handlePayout(e, "approve", selectedId, "single");
  };

  const handleChange = e => {
    setUniqueInfo(e.target.value);
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to reject this payout method?"}
          onConfirm={handleConfirm}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"Payout Method Approval"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center">
              <PrimarySearch
                placeholder={"Search by store name, email"}
                value={searchVal}
                handleKeyPress={handleKeyPress}
                handleChange={handleSearch}
              />
              <div className="d-flex ms-3">
                <select
                  className="primary-select-option me-3"
                  value={status || ""}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                  onKeyPress={handleFilter}
                >
                  <option value={""}>Search By Status</option>
                  <option value={-1}>Pending</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                  <option value={5}>Disabled</option>
                  {/* <option value={3}>Freeze</option> */}
                </select>
                <div style={{ minWidth: "250px" }}>
                  <Select
                    isMulti={false}
                    options={options}
                    onChange={handleSelectChange}
                    value={payoutStatus}
                    name={"payout_method"}
                    className="react-select-container"
                    styles={customStyles}
                    placeholder={"Search By Payout Method Type"}
                  />
                </div>
                <Button
                  color="primary"
                  className={`btn-rounded ms-3`}
                  onClick={handleFilter}
                >
                  Apply Filter
                </Button>
                {paramsLength > 0 && <ResetButton />}
              </div>
            </div>
          </div>

          {selectedRowKeys?.length > 0 && (
            <div
              className={`d-flex align-items-center justify-content-end mt-3`}
            >
              {hasSellerRejectPer ? (
                <Button
                  className="rounded-full me-2"
                  onClick={handlePopup}
                  color="danger"
                  disabled={loading}
                >
                  <img src={banIcon} alt="tick icon" className="pe-2" />
                  Reject and Ban
                </Button>
              ) : null}
              {permissions.includes("hyperwallet-reject-seller") && (
                <Button
                  className="rounded-full me-2"
                  onClick={e => handlePayout(e, "reject")}
                  color="danger"
                  disabled={loading}
                >
                  <img src={cancleIcon} alt="tick icon" className="pe-2" />
                  Reject
                </Button>
              )}
              {permissions.includes("hyperwallet-approve-seller") && (
                <Button
                  className="rounded-full"
                  color="primary"
                  onClick={e => handlePayout(e, "approve")}
                  disabled={loading}
                >
                  <img src={tickIcon} alt="tick icon" className="pe-2" />
                  Approve
                </Button>
              )}
            </div>
          )}
          <div className="mt-3 table-responsive">
            <PrimaryTable
              columns={columns}
              data={data}
              selectRow={selectRow}
              bordered={false}
              keyField="id"
              responsive
              isPermission={permissions?.includes("hyperwallet-seller-listing")}
              rowUrl={"/seller/payout-approval"}
            />
            {payoutsApprovalList?.data?.length > 0 && lastPage > 1 && (
              <PrimaryPagination
                setPageNo={setPageNo}
                pageCount={parseInt(lastPage)}
                pageNo={parseInt(pageNo)}
              />
            )}
          </div>
        </CardBody>
      </Card>
      {isApprovedPayout && (
        <ApprovePaymentPopup
          handleChange={handleChange}
          handleClick={handleClosePopup}
          uniqueInfo={uniqueInfo}
          isOpen={isApprovedPayout}
          handleSubmit={handlePayoutApprove}
        />
      )}

      {isRejectBan && (
        <SellerActionPopup
          loading={loading}
          fields={actionFields}
          handleChange={handleSellerRejectBan}
          isRejectBan={isRejectBan}
          handleSubmit={handleSellerAction}
          handlePopup={handlePopup}
        />
      )}
    </div>
  );
}

export default PayoutMethodsApproval;
