import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getSingleCategory, updateCategory } from "services/ProductsServices";
import { useParams } from "react-router-dom";
import CategoryForm from "components/Products/Categories/CategoryForm";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditCategory() {
  const history = useNavigate();
  const { id } = useParams();
  const [fields, setFields] = useState({});
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    setPageTitle("Edit Product Category - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleCategory(id)
      .then(res => {
        setFields({
          name: res?.data?.name || undefined,
          slug: res?.data?.slug || undefined,
          type: res?.data.parent_id > 0 ? "sub_category" : "parent",
          parent_id: res?.data.parent_id || undefined,
          seo_description: res?.data?.seo_description || undefined,
          seo_title: res?.data?.seo_title || undefined,
          google_category_id: res?.data?.google_category_id || undefined,
          is_hidden: res?.data?.is_hidden == 1 ? true : false,
          related_group_id: res?.data?.related_group_id || undefined,
          non_updatable: res?.data?.non_updatable == 1 ? true : false,
        });
        if (res?.data?.image) {
          setImage({
            preview: res?.data?.image || undefined,
            name: "media_image",
          });
        }
        setDescription(res?.data?.description || undefined);
        let faqParse = res.data?.faq ? JSON.parse(res.data?.faq) : undefined;
        setFaq(faqParse);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleEditor = event => {
    const { name, value } = event;
    if (name === "description") {
      setDescription(value);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target?.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleSubmit = e => {
    const filteredFaq = faq?.filter(
      item => item["question"] !== "" && item["answer"] !== ""
    );
    e.preventDefault();
    const data = {
      ...fields,
      parent_id: fields?.type === "sub_category" ? fields?.parent_id : 0,
      description: description || undefined,
      image: image?.name !== "media_image" ? image : undefined,
      faq: filteredFaq,
      is_hidden: fields?.is_hidden ? 1 : 0,
      non_updatable: fields?.non_updatable == true ? Number(1) : Number(0),
    };
    toast.dismiss();
    setLoading(true);
    updateCategory(data, id)
      .then(res => {
        history("/products/categories");
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array?.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleAddQuestion = () => {
    setFaq(prevFaq => {
      const newFAQ = {
        question: "",
        answer: "",
      };
      return [...(prevFaq || []), newFAQ];
    });
  };

  const handleDelete = itemIndex => {
    if (faq.length > 1) {
      const filterFAQ = faq.filter((item, index) => index !== itemIndex);
      setFaq(filterFAQ);
    }
  };

  const handleFaq = (e, index) => {
    let faqObj = [...faq];
    if (e.name == "answer") {
      faqObj[index][e.name] = e.value;
    } else {
      let name = e.target.name;
      let value = e.target.value;
      faqObj[index][name] = value;
    }
    setFaq(faqObj);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Product Categories"} />
          <div className="d-flex align-items-center">
            <Button
              className="me-3"
              onClick={() => history("/products/categories")}
            >
              Cancel
            </Button>
            <Button color="primary">Save</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <CategoryForm
              fields={fields}
              handleAddQuestion={handleAddQuestion}
              faq={faq}
              handleChange={handleChange}
              description={description}
              image={image}
              handleEditor={handleEditor}
              setImage={setImage}
              handleDelete={handleDelete}
              handleFaq={handleFaq}
              setLoading={setLoading}
              setFields={setFields}
              path="edit"
            />
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditCategory;
