import moment from "moment";
import "moment-timezone";

export const getCurrentTimeInEST = () => {
  const inputDate = new Date();
  const inputTimeZone = "Asia/Karachi";

  // Parse the input date with the given format and timezone
  const parsedDate = moment.tz(
    inputDate,
    "YYYY-MM-DD hh:mm:ss a",
    inputTimeZone
  );

  // Convert it to Eastern Standard Time (EST)
  const estTimeZone = "America/New_York"; // Eastern Standard Time
  const estDateTime = parsedDate
    .clone()
    .tz(estTimeZone)
    .format("YYYY-MM-DD hh:mm:ss A");
  return estDateTime;
};

export function convertLocalToUTC(date) {
  return moment(date).utc().format("YYYY-MM-DDTHH:mm");
}

export function convertLocalDatesToUTC(startDate, endDate) {
  // Get the user's time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Set the start of the day for the start date in the user's time zone
  const startUtc = moment
    .tz(startDate, userTimeZone)
    .startOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");

  // Set the end of the day for the end date in the user's time zone
  const endUtc = moment
    .tz(endDate, userTimeZone)
    .endOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");

  return { startUtc, endUtc };
}

export function convertUtcDateToLocal(date) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = moment
    .utc(date)
    .tz(userTimeZone)
    .format("YYYY-MM-DD HH:mm:ss");
  return localTime;
}

function areDatesSameDay(startDate, endDate, userTimeZone) {
  const localStartDate = moment(startDate).tz(userTimeZone).startOf("day");
  const localEndDate = moment(endDate).tz(userTimeZone).startOf("day");
  return localStartDate.isSame(localEndDate, "day");
}

export function convertGraphDataToLocal(data, startDate, endDate) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return data?.map(item => {
    const utcTime = moment.utc(item.time); // Parse the UTC time
    const localTime = utcTime.tz(userTimeZone); // Convert to local time

    if (areDatesSameDay(startDate, endDate, userTimeZone)) {
      // If the date is today, return only the time (hour)
      return {
        time: localTime.format("h:mm A"), // Format the time for today (e.g., "9 AM")
        orders: item.orders,
      };
    } else {
      // If the date is not today, return full local date and time
      return {
        time: localTime.format("DD-MMM"), // Full date and time
        orders: item.orders,
      };
    }
  });
}
