import React from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import {
  actionSeller,
  getSinglePayoutMethod,
  payoutMethodApprove,
  payoutMethodReject,
} from "services/SellerPayoutApprovals";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SellerActionPopup from "components/Common/Popups/SellerActionPopup";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import GlobalService from "services/GlobalService";
import ImagePreviewPopup from "components/Common/Popups/ImagePreviewPopup";
import ApprovePaymentPopup from "components/Common/Popups/ApprovePaymentPopup";

function SinglePayoutApproval() {
  const [getPaymentDetail, setPaymentDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [actionFields, setActionFields] = useState({});
  const [payoutStatus, setPayoutStatus] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [isPreviewFile, setIsPreviewFile] = useState(false);
  const [isRejectBan, setRejectBan] = useToggle(false);
  const [uniqueInfo, setUniqueInfo] = useState("");
  const [isApprovedPayout, setIsApprovedPayout] = useState(false);
  const user = useSelector(state => state?.auth?.user);
  const globalService = GlobalService();
  const hasPaymentApprovePer = user?.permissions?.some(
    p => p.name === "hyperwallet-approve-seller"
  );
  const hasPaymentRejectPer = user?.permissions?.some(
    p => p.name === "hyperwallet-reject-seller"
  );
  const hasSellerRejectPer = user?.permissions?.some(
    p => p.name === "hyperwallet-reject-seller-ban"
  );

  const location = useParams();
  const { id } = location;

  useEffect(() => {
    setPageTitle("Payout Method Approval - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSinglePayoutMethod(id)
      .then(res => {
        if (res.data[0]?.seller_kyc) {
          globalService
            .getCountries()
            .then(response => {
              let countryData = response?.data?.data?.map(item => {
                return {
                  name: item.name,
                  alpha2Code: item?.alpha2Code?.toLowerCase(),
                };
              });
              let selectedCountry = countryData?.find(
                country =>
                  country.alpha2Code ==
                  res?.data[0]?.seller_kyc?.proof_of_identity?.country?.toLowerCase()
              )?.name;
              let kycData = {
                proof_of_address: {
                  ...res.data[0]?.seller_kyc?.proof_of_address,
                },
                proof_of_identity: {
                  ...res.data[0]?.seller_kyc?.proof_of_identity,
                  country: selectedCountry,
                },
              };

              setPaymentDetail({
                ...res.data[0],
                seller_kyc: kycData,
              });
            })
            .catch(error => console.log(error.message));
        } else {
          setPaymentDetail(res.data[0]);
        }
        switch (res.data[0]?.status) {
          case 1:
            setPayoutStatus("Approved");
            break;
          case 2:
            setPayoutStatus("Rejected");

            break;
          case 5:
            setPayoutStatus("Disabled");

            break;
          default:
            setPayoutStatus("Pending");
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleConfirm = () => {
    handlePayout("reject");
  };
  const handlePayout = status => {
    const data = {
      payout_method_ids: [id],
      unique_info: uniqueInfo ? uniqueInfo : undefined || undefined,
    };
    toast.dismiss();
    if (status === "approve") {
      setLoading(true);
      payoutMethodApprove(data)
        .then(res => {
          if (res.status === 200) {
            PrimaryToast({ type: "success", message: res?.data?.message });
          }
          setPayoutStatus("Approved");
          setPaymentDetail({
            ...getPaymentDetail,
            status: 1,
            approved_by: user?.name,
            data: {
              ...getPaymentDetail?.data,
            },
          });
          handleClosePopup();
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    } else if (status === "reject") {
      setLoading(true);
      payoutMethodReject(data)
        .then(res => {
          if (res.status === 200) {
            PrimaryToast({ type: "success", message: res?.data?.message });
            setPaymentDetail({
              ...getPaymentDetail,
              status: 2,
              approved_by: user?.name,
            });
            setPayoutStatus("Rejected");
            setShowDeleteModal(false);
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    }
  };

  const handlePopup = () => {
    setRejectBan(!isRejectBan);
  };

  const handleSellerAction = () => {
    const data = {
      seller_ids: [getPaymentDetail?.seller?.id],
      reason: actionFields?.reason,
      ban_status: actionFields?.ban_status ? 2 : 0,
      payout_ids: [id],
    };
    setLoading(true);
    actionSeller(data)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        handlePopup();
        setPaymentDetail({
          ...getPaymentDetail,
          status: 2,
          approved_by: user?.name,
          seller: {
            ...getPaymentDetail?.seller,
            is_active: data?.ban_status,
          },
        });
        setPayoutStatus("Rejected");
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  const handleSellerRejectBan = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldObj = { ...actionFields };
    fieldObj[name] = value;
    setActionFields(fieldObj);
  };

  const handleFile = url => {
    setFileUrl(url);
    setIsPreviewFile(true);
  };

  const handleClosePopup = () => {
    setIsApprovedPayout(false);
    setUniqueInfo("");
    setSelectedId("");
  };

  const handlePayoutApprove = e => {
    handlePayout("approve");
  };

  const handleChange = e => {
    setUniqueInfo(e.target.value);
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to reject this payout method?"}
          onConfirm={handleConfirm}
          onCancel={() => {
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}

      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <TopHeading heading={"Payout Method Approval"} />
          <div
            className={`status ${
              payoutStatus === "Approved"
                ? "success-status"
                : payoutStatus === "Pending"
                ? "warning-status"
                : payoutStatus === "Rejected" || payoutStatus == "Disabled"
                ? "danger-status"
                : ""
            }  ms-3`}
          >
            {payoutStatus}
          </div>
        </div>
      </div>
      <div className="mt-4 d-flex align-items-start">
        <div className="w-75 me-3">
          <Card>
            <CardBody>
              <h4 className="font-semi-bold">Seller Information</h4>
              <Row className="py-2 border-top border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Name:</div>
                    <div className="w-50">{getPaymentDetail?.seller?.name}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-25">Email:</div>
                    <div className="w-75">
                      {getPaymentDetail?.seller?.email}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Store Name:</div>
                    <div className="w-50">
                      {getPaymentDetail?.seller?.business_name}
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  {getPaymentDetail?.seller?.rating && (
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-25">Ratings:</div>
                      <div className="w-75">
                        <span
                          className="p-1 text-light rounded"
                          style={{ background: "#34C38F" }}
                        >
                          {getPaymentDetail?.seller?.rating}
                        </span>{" "}
                        <span className="ps-2">out of 5 (165 Reviews)</span>{" "}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Seller Status:</div>
                    <div className="w-50">
                      <div
                        className={`status  w-max-content ${
                          getPaymentDetail?.seller?.is_verify === 0 &&
                          getPaymentDetail?.seller?.is_active === 1
                            ? "warning-status"
                            : (getPaymentDetail?.seller?.is_verify === 0 ||
                                getPaymentDetail?.seller?.is_verify === 1) &&
                              getPaymentDetail?.seller?.is_active === 0
                            ? "danger-status"
                            : (getPaymentDetail?.seller?.is_verify === 0 ||
                                getPaymentDetail?.seller?.is_verify === 1) &&
                              getPaymentDetail?.seller?.is_active === 2
                            ? "danger-status"
                            : getPaymentDetail?.seller?.is_verify === 1 &&
                              getPaymentDetail?.seller?.is_active === 1
                            ? "success-status"
                            : null
                        }`}
                      >
                        {getPaymentDetail?.seller?.is_verify === 0 &&
                        getPaymentDetail?.seller?.is_active === 1
                          ? "Not Verified"
                          : (getPaymentDetail?.seller?.is_verify === 0 ||
                              getPaymentDetail?.seller?.is_verify === 1) &&
                            getPaymentDetail?.seller?.is_active === 0
                          ? "Temporary Ban"
                          : (getPaymentDetail?.seller?.is_verify === 0 ||
                              getPaymentDetail?.seller?.is_verify === 1) &&
                            getPaymentDetail?.seller?.is_active === 2
                          ? "Banned"
                          : getPaymentDetail?.seller?.is_verify === 1 &&
                            getPaymentDetail?.seller?.is_active === 1
                          ? "verified"
                          : null}
                      </div>
                    </div>
                  </div>
                </Col>
                {getPaymentDetail?.seller?.verify_by && (
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-25">
                        Seller Verified By:
                      </div>
                      <div className="w-75">
                        {getPaymentDetail?.seller?.verify_by}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          {getPaymentDetail?.data?.payment_method_type?.toLowerCase() !==
            "usdc" &&
            getPaymentDetail?.data?.payment_method_type !== "payoneer" && (
              <Card className="mt-3">
                <CardBody>
                  <h5 className="font-semi-bold">Seller Tax Information</h5>
                  {getPaymentDetail?.data?.business_entity && (
                    <Row className="py-2 border-top border-bottom">
                      {getPaymentDetail?.data?.business_entity && (
                        <Col sm="6">
                          <div className="d-flex justify-content-between">
                            <div className="font-semi-bold w-50">
                              Business Entity:
                            </div>
                            <div className="w-50">
                              {getPaymentDetail?.data?.business_entity?.toUpperCase()}
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col sm="6">
                        {getPaymentDetail?.data?.business_entity ===
                          "individual" && (
                          <div className="d-flex justify-content-between">
                            <div className="font-semi-bold w-25">SSN:</div>
                            <div className="w-75">
                              {getPaymentDetail?.data?.ssn}
                            </div>
                          </div>
                        )}
                        {getPaymentDetail?.data?.business_entity ===
                          "business" && (
                          <div className="d-flex justify-content-between">
                            <div className="font-semi-bold w-25">EIN:</div>
                            <div className="w-75">
                              {getPaymentDetail?.data?.ein}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}

                  {getPaymentDetail?.data?.business_name && (
                    <Row className="py-2 border-bottom">
                      <Col sm="6">
                        <div className="d-flex justify-content-between">
                          <div className="font-semi-bold w-50">
                            Business name:
                          </div>
                          <div className="w-50">
                            {getPaymentDetail?.data?.business_name}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row className="py-2 border-bottom">
                    <Col sm="12">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">Address:</div>
                        <div className="w-75">
                          {getPaymentDetail?.data?.address}{" "}
                          {getPaymentDetail?.data?.city},{" "}
                          {getPaymentDetail?.data?.province},{" "}
                          {getPaymentDetail?.data?.zip_code},{" "}
                          {getPaymentDetail?.data?.country}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          <Card className="mt-3">
            <CardBody>
              <h4 className="font-semi-bold">Seller Payout Information</h4>
              <Row className="py-2 border-top border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Name:</div>
                    <div className="w-50">{getPaymentDetail?.data?.name}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">
                      Payout Method Type:
                    </div>
                    <div className="w-50">
                      {getPaymentDetail?.data?.payment_method_type}
                    </div>
                  </div>
                </Col>
              </Row>
              {((getPaymentDetail?.data?.transfer_currency &&
                getPaymentDetail?.data?.payment_method_type ===
                  "hyperwallet_paypal") ||
                getPaymentDetail?.data?.payment_method_type ===
                  "hyperwallet_wire") && (
                <Row className="py-2 border-top border-bottom">
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">
                        Transfer Method currency:
                      </div>
                      <div className="w-50">
                        {getPaymentDetail?.data?.transfer_currency}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {getPaymentDetail?.data?.payment_method_type ===
                "hyperwallet_paypal" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">Paypal Email:</div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.paypal_email}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {getPaymentDetail?.data?.payment_method_type === "usdt" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">Network:</div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.usdt_network}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">Address</div>
                        <div className="w-75">
                          {getPaymentDetail?.data?.usdt_address}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {getPaymentDetail?.data?.payment_method_type === "usdc" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">Network:</div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.usdc_network}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">Address</div>
                        <div className="w-75">
                          {getPaymentDetail?.data?.usdc_address}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {getPaymentDetail?.data?.payment_method_type === "payoneer" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Payoneer Name:
                        </div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.payoneer_name}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">
                          Payoneer Email:
                        </div>
                        <div className="w-75">
                          {getPaymentDetail?.data?.payoneer_email}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {getPaymentDetail?.data?.payment_method_type ===
                "hyperwallet_wire" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Owner of Bank Account:
                        </div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.owner_bank_account_name}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          bank routing number:
                        </div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.branch_id}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Bank Account Number:{" "}
                        </div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.account_number}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Bank Account Type:
                        </div>
                        <div className="w-50">
                          {getPaymentDetail?.data?.bank_account_purpose}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2 border-bottom">
                    <Col sm="12">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">Address:</div>
                        <div className="w-75">
                          {getPaymentDetail?.data?.address}{" "}
                          {getPaymentDetail?.data?.city},{" "}
                          {getPaymentDetail?.data?.province},{" "}
                          {getPaymentDetail?.data?.zip_code},{" "}
                          {getPaymentDetail?.data?.country || "N/A"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {payoutStatus !== "Pending" && (
                <Row className="py-2 border-bottom">
                  <Col sm="12">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-25">
                        Payout Method {payoutStatus} By:
                      </div>
                      <div className="w-75">
                        {getPaymentDetail?.approved_by}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {getPaymentDetail?.data?.usdc_recipient_id ? (
                <Row className="py-2 border-bottom">
                  <Col sm="12">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-25">
                        USDC Recipient ID:
                      </div>
                      <div className="w-75">
                        {getPaymentDetail?.data?.usdc_recipient_id}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </CardBody>
          </Card>
          {getPaymentDetail?.seller_kyc && (
            <Card>
              <CardBody>
                <h4 className="font-semi-bold">KYC</h4>
                <Row className="py-2 border-bottom border-top">
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">Document Type: </div>
                      <div className="w-50">
                        {
                          getPaymentDetail?.seller_kyc?.proof_of_identity
                            ?.document_type
                        }
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">Issue Country:</div>
                      <div className="w-50">
                        {
                          getPaymentDetail?.seller_kyc?.proof_of_identity
                            ?.country
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                {(getPaymentDetail?.seller_kyc?.proof_of_identity
                  ?.attachment_front ||
                  getPaymentDetail?.seller_kyc?.proof_of_identity
                    ?.attachment_back) && (
                  <Row className="py-2">
                    {getPaymentDetail?.seller_kyc?.proof_of_identity
                      ?.attachment_front && (
                      <Col sm="12  border-bottom pb-2">
                        <div className="d-flex justify-content-between">
                          <div
                            className="font-semi-bold"
                            style={{
                              width: "fit-content",
                              flexShrink: 0,
                              marginRight: "5px",
                            }}
                          >
                            Front Of{" "}
                            {
                              getPaymentDetail?.seller_kyc?.proof_of_identity
                                ?.document_type
                            }
                            :{" "}
                          </div>
                          <div style={{ flexGrow: 1 }}>
                            {getPaymentDetail?.seller_kyc?.proof_of_identity?.attachment_front?.match(
                              /\.(jpeg|jpg|gif|png|svg)$/i
                            ) ? (
                              <img
                                src={
                                  getPaymentDetail?.seller_kyc
                                    ?.proof_of_identity?.attachment_front
                                }
                                style={{
                                  width: "100%",
                                  maxHeight: "400px",
                                  objectFit: "contain",
                                }}
                                alt="front image"
                                onClick={() =>
                                  handleFile(
                                    getPaymentDetail?.seller_kyc
                                      ?.proof_of_identity?.attachment_front
                                  )
                                }
                              />
                            ) : getPaymentDetail?.seller_kyc?.proof_of_identity?.attachment_front?.match(
                                /\.(pdf|doc|docx)$/i
                              ) ? (
                              <div
                                style={{
                                  maxHeight: "500px",
                                  overflow: "auto",
                                  height: "500px",
                                }}
                              >
                                <object
                                  data={
                                    getPaymentDetail?.seller_kyc
                                      ?.proof_of_identity?.attachment_front
                                  }
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                  style={{ border: "none" }}
                                ></object>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                    {getPaymentDetail?.seller_kyc?.proof_of_identity
                      ?.attachment_back && (
                      <Col sm="12 mt-4 pb-2 border-bottom">
                        <div className="d-flex justify-content-between">
                          <div
                            className="font-semi-bold"
                            style={{
                              width: "fit-content",
                              flexShrink: 0,
                              marginRight: "5px",
                            }}
                          >
                            Back Of{" "}
                            {
                              getPaymentDetail?.seller_kyc?.proof_of_identity
                                ?.document_type
                            }
                            :{" "}
                          </div>
                          <div style={{ flexGrow: 1 }}>
                            {getPaymentDetail?.seller_kyc?.proof_of_identity?.attachment_back?.match(
                              /\.(jpeg|jpg|gif|png|svg)$/i
                            ) ? (
                              <img
                                src={
                                  getPaymentDetail?.seller_kyc
                                    ?.proof_of_identity?.attachment_back
                                }
                                style={{
                                  width: "100%",
                                  maxHeight: "400px",
                                  objectFit: "contain",
                                }}
                                alt="front image"
                                onClick={() =>
                                  handleFile(
                                    getPaymentDetail?.seller_kyc
                                      ?.proof_of_identity?.attachment_back
                                  )
                                }
                              />
                            ) : getPaymentDetail?.seller_kyc?.proof_of_identity?.attachment_back?.match(
                                /\.(pdf|doc|docx)$/i
                              ) ? (
                              <div
                                style={{
                                  maxHeight: "500px",
                                  overflow: "auto",
                                  height: "500px",
                                }}
                              >
                                <object
                                  data={
                                    getPaymentDetail?.seller_kyc
                                      ?.proof_of_identity?.attachment_back
                                  }
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                  style={{ border: "none" }}
                                ></object>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                <h4 className="font-semi-bold mt-3">Proof of Address</h4>
                <Row className="py-2">
                  <Col sm="6 pb-2 border-bottom">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">Document Type :</div>
                      <div className="w-50">
                        {
                          getPaymentDetail?.seller_kyc?.proof_of_address
                            ?.document_type
                        }
                      </div>
                    </div>
                  </Col>
                  {getPaymentDetail?.seller_kyc?.proof_of_address
                    ?.attachment_front && (
                    <Col sm="12 border-bottom pb-2 mt-2">
                      <div className="d-flex justify-content-between">
                        <div
                          className="font-semi-bold"
                          style={{
                            width: "fit-content",
                            flexShrink: 0,
                            marginRight: "5px",
                          }}
                        >
                          Front Of{" "}
                          {
                            getPaymentDetail?.seller_kyc?.proof_of_address
                              ?.document_type
                          }
                          :{" "}
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          {getPaymentDetail?.seller_kyc?.proof_of_address?.attachment_front?.match(
                            /\.(jpe?g|gif|png|svg)$/i
                          ) ? (
                            <img
                              src={
                                getPaymentDetail?.seller_kyc?.proof_of_address
                                  ?.attachment_front
                              }
                              style={{
                                width: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                              }}
                              alt="front image"
                              onClick={() =>
                                handleFile(
                                  getPaymentDetail?.seller_kyc?.proof_of_address
                                    ?.attachment_front
                                )
                              }
                            />
                          ) : getPaymentDetail?.seller_kyc?.proof_of_address?.attachment_front?.match(
                              /\.(pdf|doc|docx)$/i
                            ) ? (
                            <div
                              style={{
                                maxHeight: "500px",
                                overflow: "auto",
                                height: "500px",
                              }}
                            >
                              <object
                                data={
                                  getPaymentDetail?.seller_kyc?.proof_of_address
                                    ?.attachment_front
                                }
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              ></object>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
        <div className="w-25">
          {payoutStatus === "Pending" && (
            <div>
              {hasPaymentApprovePer && (
                <Button
                  color="primary"
                  className="w-100 mb-3"
                  onClick={() => {
                    setIsApprovedPayout(true);
                  }}
                  disabled={loading}
                >
                  Approve
                </Button>
              )}
              {hasPaymentRejectPer && (
                <Button
                  color="primary"
                  className="w-100 mb-3"
                  onClick={() => {
                    setShowDeleteModal(!showDeleteModal);
                  }}
                  disabled={loading}
                >
                  Reject
                </Button>
              )}
              {hasSellerRejectPer && (
                <Button
                  color="primary"
                  className={`w-100 mb-3`}
                  onClick={handlePopup}
                  disabled={loading}
                >
                  Reject & Ban Seller
                </Button>
              )}
            </div>
          )}
          {getPaymentDetail?.detected_seller?.length > 0 ? (
            getPaymentDetail.detected_seller?.some(
              item => item?.business_name
            ) ? (
              // If business_name exists, run this block
              getPaymentDetail?.detected_seller?.map((item, index) => {
                return (
                  <Card className="" key={index}>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Seller {index + 1}</h5>
                        {item?.status != undefined || item?.status != null ? (
                          <p
                            className={`status ${
                              item?.status == 1
                                ? "success-status"
                                : "danger-status"
                            }`}
                          >
                            {item.status == 0
                              ? "Temporary Ban"
                              : item.status == 1
                              ? "Active"
                              : item.status == 2
                              ? "Permanent Ban"
                              : item?.status == 3
                              ? "Delete"
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="d-flex align-items-center">
                        <p className="me-4 fw-bold mb-2">Business Name:</p>
                        <p className="mb-2">{item?.business_name}</p>
                      </div>
                      <div>
                        {Object.entries(item?.data).map(
                          ([key, value], index1) => {
                            return key !== "business_name" ? (
                              <div
                                className="d-flex align-items-center"
                                key={index1}
                              >
                                <p className="fw-bold me-4 text-capitalize mb-2">
                                  {key?.replaceAll("_", " ")}:
                                </p>
                                <p className="mb-2">{value}</p>
                              </div>
                            ) : null;
                          }
                        )}
                      </div>
                    </CardBody>
                  </Card>
                );
              })
            ) : (
              // Otherwise, run this block
              <Card className="">
                <CardBody>
                  <h4 className="font-semi-bold mb-3">Detected Seller</h4>
                  <ul>
                    {getPaymentDetail?.detected_seller?.map((item, index) => (
                      <li
                        key={index}
                        style={{ fontWeight: "500", marginBottom: "0.5rem" }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            )
          ) : null}
        </div>
      </div>
      {isRejectBan && (
        <SellerActionPopup
          loading={loading}
          fields={actionFields}
          handleChange={handleSellerRejectBan}
          isRejectBan={isRejectBan}
          handleSubmit={handleSellerAction}
          handlePopup={handlePopup}
        />
      )}
      {isApprovedPayout && (
        <ApprovePaymentPopup
          handleChange={handleChange}
          handleClick={handleClosePopup}
          uniqueInfo={uniqueInfo}
          isOpen={isApprovedPayout}
          handleSubmit={handlePayoutApprove}
        />
      )}
      {isPreviewFile && (
        <ImagePreviewPopup
          isOpen={isPreviewFile}
          handleClick={() => {
            setIsPreviewFile(false);
            setFileUrl("");
          }}
          url={fileUrl}
        />
      )}
    </div>
  );
}

export default SinglePayoutApproval;
