import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import DateFilter from "components/Common/DateFilter";
import PrimaryCard from "components/Common/PrimaryCard";
import { Row, Col } from "reactstrap";
import WebIcon from "../../assets/images/web-icon.svg";
import IosIcon from "../../assets/images/ios-icon.svg";
import AndriodIcon from "../../assets/images/andriod-icon.svg";
import CartIcon from "../../assets/images/total-cart.svg";
import { getSalesByChannel } from "services/ReportsServices";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import moment from "moment";

function SalesByChannel() {
  const [channelData, setChannelData] = useState();
  const [loading, setLoading] = useState(false);
  const [dates, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    setPageTitle("Sales By Channel - Inspire Uplift");
  }, []);

  useEffect(() => {
    let startDate;
    let endDate;
    if (moment(dates?.startDate).isSame(dates?.endDate, "day")) {
      // If start and end date are the same, set the specific times
      startDate = moment(dates?.startDate)
        .startOf("day")
        .add(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
      endDate = moment(dates?.endDate)
        .endOf("day")
        .subtract(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      // Otherwise, format the start and end dates as usual
      startDate = moment(dates?.startDate).format("YYYY-MM-DD");
      endDate = moment(dates?.endDate).format("YYYY-MM-DD");
    }

    setLoading(true);
    getSalesByChannel(startDate, endDate)
      .then(res => {
        setChannelData(res?.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [dates?.startDate, dates?.endDate]);

  return (
    <div className="page-content reports-page-container">
      {loading && <PrograssPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Sales By Channel"} />
        <DateFilter setDate={setDate} dates={dates} />
      </div>
      <Row>
        <Col>
          <PrimaryCard icon={WebIcon} title="Web" count={channelData?.web} />
        </Col>
        <Col>
          <PrimaryCard icon={IosIcon} title="IOS" count={channelData?.ios} />
        </Col>
        <Col>
          <PrimaryCard
            icon={AndriodIcon}
            title="Android"
            count={channelData?.android > 0 ? channelData?.android : "0"}
          />
        </Col>
        <Col>
          <PrimaryCard
            icon={CartIcon}
            title="Total"
            count={channelData?.total}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SalesByChannel;
