import { getApi } from "./BaseService";
import moment from "moment";


export const getDashBoardData = (start,end) => {
    return getApi({
        url:`admin/dashboard`,
        data:{
            is_admin:true,
            startDate:start|| undefined,
            endDate:end|| undefined,
        },

    }).then(res => (res.data))
}