import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import moment from "moment";

// Date Filter
import DateFilter from "components/Common/DateFilter";

import TopHeading from "../../components/Common/TopHeading";

import DashBoardCardsSection from "components/Dashboard/DashboardCardSection";

import { getDashBoardData } from "services/DashboardService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import DashboardBelowSection from "components/Dashboard/DashboardBelowSection";

const Dashboard = props => {
  const [loading, setLoading] = useState(false);
  const [dates, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [data, setData] = useState();

  useEffect(() => {
    setPageTitle("Dashboard- inspireuplift");
  }, []);

  useEffect(() => {
    let startDate;
    let endDate;
    if ( moment(dates?.startDate).isSame(dates?.endDate, "day")) {
      // If start and end date are the same, set the specific times
      startDate = moment(dates?.startDate)
        .startOf("day")
        .add(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
      endDate = moment(dates?.endDate)
        .endOf("day")
        .subtract(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      // Otherwise, format the start and end dates as usual
      startDate = moment(dates?.startDate).format("YYYY-MM-DD");
      endDate = moment(dates?.endDate).format("YYYY-MM-DD");
    }

    setLoading(true);
    getDashBoardData(startDate, endDate)
      .then(res => {
        setData(res?.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [dates?.startDate, dates?.endDate]);

  return (
    <React.Fragment>
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <div className="d-flex justify-content-between align-item-center mb-3">
            <TopHeading heading={"Dashboard"} />
            <DateFilter setDate={setDate} dates={dates} />
          </div>
          <div>
            <DashBoardCardsSection data={data} />
          </div>
          <div>
            <DashboardBelowSection data={data} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
