import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import DateFilter from "components/Common/DateFilter";
import ChartApex from "components/Dashboard/ChartApex";
import { CardBody, Card } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { getOverTimeData } from "services/ReportsServices";
import { setPageTitle } from "utils/pageTitle";

import ProgressPopup from "components/Common/Popups/ProgressPopup";
import moment from "moment";

function OverTime() {
  const [overtimeData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  //   const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    setPageTitle("Sales Over Time - Inspire Uplift");
  }, []);

  useEffect(() => {
    let startDate;
    let endDate;
    if (moment(dates?.startDate).isSame(dates?.endDate, "day")) {
      // If start and end date are the same, set the specific times
      startDate = moment(dates?.startDate)
        .startOf("day")
        .add(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
      endDate = moment(dates?.endDate)
        .endOf("day")
        .subtract(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      // Otherwise, format the start and end dates as usual
      startDate = moment(dates?.startDate).format("YYYY-MM-DD");
      endDate = moment(dates?.endDate).format("YYYY-MM-DD");
    }

    setLoading(true);
    getOverTimeData(startDate, endDate)
      .then(res => {
        setOrdersData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [dates?.startDate, dates?.endDate]);

  const isToday = someDate => {
    const today = moment();
    return someDate.isSame(today, "day");
  };

  const columns = [
    {
      dataField: "time",
      text: "Date",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        const columnTitle = isToday(moment(dates?.startDate)) ? "Time" : "Date";
        return <div>{columnTitle}</div>;
      },
    },
    {
      dataField: "count",
      text: "Orders",
    },
    {
      dataField: "grass_sales",
      text: "Grass Sales",
      formatter: (cell, row) => {
        let sale = row.amount + row.discount;
        let formatGrassSale = sale?.toFixed(2);
        return <div>{formatGrassSale}</div>;
      },
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cell, row) => {
        let discount = row.discount;
        let formatDiscount = discount?.toFixed(2);
        return <div>{formatDiscount}</div>;
      },
    },
    {
      dataField: "tax",
      text: "Tax",
      formatter: (cell, row) => {
        let tax = row.tax;
        let formatTax = tax?.toFixed(2);
        return <div>{formatTax}</div>;
      },
    },
    {
      dataField: "refund_amount",
      text: "Returns",
      formatter: (cell, row) => {
        let refundAmount = row.refund_amount;
        let formatRefundAmount = refundAmount?.toFixed(2);
        return <div>{formatRefundAmount}</div>;
      },
    },
    {
      dataField: "refund_tax",
      text: "Tax Returns",
      formatter: (cell, row) => {
        let refundTax = row.refund_tax;
        let formatRefundTax = refundTax?.toFixed(2);
        return <div>{formatRefundTax}</div>;
      },
    },
    {
      dataField: "amount",
      text: "Net Sales",
      formatter: (cell, row) => {
        let amount = row.amount;
        let formatAmount = amount?.toFixed(2);
        return <div>{formatAmount}</div>;
      },
    },
    {
      dataField: "cost",
      text: "Cost",
      formatter: (cell, row) => {
        let cost = row.cost;
        let formatCost = cost?.toFixed(2);
        return <div>{formatCost}</div>;
      },
    },
    {
      dataField: "profit",
      text: "Profit",
      formatter: (cell, row) => {
        let profit = row.profit;
        let formatProfit = profit?.toFixed(2);
        return <div>{formatProfit}</div>;
      },
    },
  ];

  const data = overtimeData?.table_data?.map((item, index) => {
    return {
      ...item,
      index,
    };
  });

  return (
    <div className="page-content reports-page-container">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Sales Over Time"} />
        <DateFilter setDate={setDate} dates={dates} />
      </div>
      <Card>
        <CardBody>
          <ChartApex
            dataColors='["--bs-primary", "--bs-success"]'
            monthsData={overtimeData?.graph_data?.order_amount?.map(
              item => item[0]
            )}
            amountsData={overtimeData?.graph_data?.order_amount?.map(
              item => item[1]
            )}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="">
            <PrimaryTable
              columns={columns}
              data={data}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
              keyField="order_number"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default OverTime;
