import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { useSearchParams, useParams } from "react-router-dom";
import moment from "moment";

import DateRangePicker from "components/Common/DateRangePicker";
import TopHeading from "components/Common/TopHeading";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { getProductPurchasesDetail } from "services/ReportsServices";
import PolygonIcon from "assets/images/polygon.svg";

const ProductPurchasesDetail = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const { productId } = useParams();
  const [sortInfo, setSortInfo] = useState({ field: "views", order: "desc" });

  useEffect(() => {
    let startParam = urlParams?.get("start") || new Date();
    let endParam = urlParams?.get("end") || new Date();
    const pageParam = urlParams?.get("page") || undefined;
    const sortByParam = urlParams?.get("sort_by");
    const sortOfParam = urlParams?.get("sort_of");

    setPageNo(pageParam || 1);
    setSortInfo(prevSortInfo => ({
      ...prevSortInfo,
      order: sortByParam,
      field: sortOfParam,
    }));
    if (startParam && endParam) {
      setDates([
        {
          startDate: moment(startParam).startOf("day").toDate(),
          endDate: moment(endParam).endOf("day").toDate(),
          key: "selection",
        },
      ]);
    }

    if (startParam && endParam && moment(startParam).isSame(endParam, "day")) {
      // If start and end date are the same, set the specific times
      startParam = moment(startParam)
        .startOf("day")
        .add(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
      endParam = moment(endParam)
        .endOf("day")
        .subtract(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      // Otherwise, format the start and end dates as usual
      startParam = moment(startParam).format("YYYY-MM-DD");
      endParam = moment(endParam).format("YYYY-MM-DD");
    }

    setLoading(true);
    getProductPurchasesDetail(
      startParam,
      endParam,
      pageParam,
      productId,
      sortOfParam,
      sortByParam
    )
      .then(res => {
        setProductData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleColumnClick = column => {
    handleFilter(
      "sort_of",
      column.dataField,
      "sort_by",
      column.dataField == sortInfo?.field
        ? sortInfo?.order === "asc"
          ? "desc"
          : "asc"
        : "desc"
    );
  };

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate)
          .local()
          .startOf("day")
          .toDate(),
        endDate: moment(item.selection.endDate).local().endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const columns = [
    {
      dataField: "referrer",
      text: "Referrer",
    },
    {
      dataField: "views",
      text: "Views",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Views{" "}
          {sortInfo.field === "views" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.views}</div>;
      },
    },
    {
      dataField: "cart",
      text: "Add to Cart",
      formatter: (cell, row) => <div>{row.addToCart}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Add to Cart{" "}
          {sortInfo.field === "cart" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "purchase",
      text: "Purchases",
      formatter: (cell, row) => <div>{row?.purchaseCount}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchases{" "}
          {sortInfo.field === "purchase" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "conversion_rate",
      text: "Conversion",
      formatter: (cell, row) => <div>{row?.conversion_rate?.toFixed(2)}</div>,
    },
    {
      dataField: "revenue",
      text: "Revenue",
      formatter: (cell, row) => <div>${row?.revenue}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Revenue{" "}
          {sortInfo.field === "revenue" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
  ];

  const rowData = productData?.map(item => {
    return {
      ...item,
    };
  });

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-4">
        <TopHeading heading={"Product Views Detail"} />
      </div>
      <Card>
        <CardBody>
          <div className="position-relative">
            <div
              className="form-control cursor-pointer rounded-full"
              style={{ color: "#74788D", width: "max-content" }}
              onClick={() => setShowCalendar(true)}
            >
              {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
              {moment(dates[0].endDate).format("DD MMM YYYY")}
            </div>

            {showCalendar && (
              <>
                <div
                  className="position-fixed top-0 start-0 w-100 h-100"
                  style={{ zIndex: "10" }}
                  onClick={() => setShowCalendar(false)}
                ></div>
                <DateRangePicker
                  dates={dates}
                  handleDatePicker={handleDatePicker}
                  handleSubmit={() => {
                    handleFilter(
                      "start",
                      moment(dates[0].startDate).format("YYYY-MM-DD"),
                      "end",
                      moment(dates[0].endDate).format("YYYY-MM-DD")
                    );
                    setShowCalendar(false);
                  }}
                  // minDate={new Date()}
                  maxDate={new Date()}
                />
              </>
            )}
          </div>
          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={columns}
              data={rowData}
              bordered={false}
              keyField="product_id"
            />
          </div>
          {rowData?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};
export default ProductPurchasesDetail;
